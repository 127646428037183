import scheduleService from '../Schedule/ScheduleService';
import type { ClinicInterface, DoctorInterface, IDoctorsRequest, IDoctorsResponse, ServiceData } from '../../index';
import { API_MODX_URL } from '../../config';
import { postToServer } from '../../util/UseFetchToServer';
type TResponsePrivateDesc = {
  data:{ok:boolean, error?:string, message?:string}
};

export default class DoctorsService {
  async fetch(request:IDoctorsRequest):Promise<IDoctorsResponse> {
    try {
      if (!request) { request = {} as IDoctorsRequest; }
      request.action = 'doctors/getDoctorsMultiList';
      request.component = 'health';
      return await postToServer(API_MODX_URL, request) as IDoctorsResponse;
      // todo create useHandleAndSaveErrorFromServer class
    } catch (error) {
      console.log('error');
      throw error;
    }

    // if (this.state.itemsAll.value?.length) { request.with('existIds', this.state.itemsAll.value?.map((doc) => doc.id).join(',')); }


  }

  public item(condition?:(doc:DoctorInterface)=>{}):DoctorInterface|null {
    if (this.state.getItems().value && condition) { return (this.state.getItems().value?.find(condition)) ?? null; }
    return null;
  }

  items(condition?:any) {
    return this.state.getItems();
  }

  count():number|null {
    return computed(() => this.state.count().value).value;
  }

  // protected prepareData
  protected prepareExtraData(doctors:DoctorInterface[]):DoctorInterface[] {
    for (const d in doctors) {
      const doctorIsset = this.state.getItem(doctors[d].id);

      const favoriteService = this.favoriteService(doctors[d]);
      if (favoriteService && doctorIsset?.favoriteService !== favoriteService) { doctors[d].favoriteService = favoriteService; }

      const specials = this.getSpecials(doctors[d]);
      const otherSpecials = this.getOtherSpecials(doctors[d]);
      const otherSpecialsCount = this.getOtherSpecialsCount(doctors[d]);
      if (specials && doctorIsset?.specials !== specials) { doctors[d].specials = specials; }
      if (otherSpecials && doctorIsset?.otherSpecials !== otherSpecials) { doctors[d].otherSpecials = otherSpecials; }
      if (otherSpecialsCount && doctorIsset?.otherSpecialsCount !== otherSpecialsCount) { doctors[d].otherSpecialsCount = otherSpecialsCount; }

      if (doctors[d].fullname) {
        doctors[d].shortFio = doctors[d].fullname.split(' ').map((part, index) => index !== 0 ? part.charAt(0) + '.' : part).join(' ');
      }
    }
    return doctors;
  }

  protected getSpecials(doctor:DoctorInterface):string {
    // console.log(doctor.main_specials?.map(special => special.name).slice(0, 3).join(' · '))
    if (doctor.seoServiceData?.[0]?.specialityTitle) {
      return doctor.seoServiceData?.[0]?.specialityTitle;
    } else if (doctor.main_specials) {
      return doctor.main_specials?.join(' · ');
    }

    return '';
  }

  protected getOtherSpecials(doctor:DoctorInterface):string {
    if (doctor.main_specials) {
      return doctor.main_specials?.join(' · ');
    }
    if (doctor.seoServiceData?.[0]?.specialityTitle) {
      return doctor.seoServiceData?.[0]?.specialityTitle;
    }
    return '';
  }

  protected getOtherSpecialsCount(doctor:DoctorInterface):number {
    return (doctor.main_specials?.slice(2).length) ? doctor.main_specials?.slice(2).length : 0;
  }

  protected specialsString(doctor:DoctorInterface):string {
    if (doctor.main_specials) { return doctor.main_specials?.map((special) => special.name).join(' · '); }
    if (doctor.seoServiceData?.[0]?.specialityTitle) { return doctor.seoServiceData?.[0]?.specialityTitle; }
    return '';
  }

  protected favoriteService(doctor:DoctorInterface):ServiceData|undefined {
    if (doctor.seoServiceData?.[0]) { return doctor.seoServiceData[0]; }
    if (doctor.choosen_service_data?.[0]) { return doctor.choosen_service_data[0]; }
    if (doctor.service_data?.[0]) { return doctor.service_data[0]; }
  }

  public async savePrivateDesc(doctor:DoctorInterface, descString:string) {
    const requestData = {
      action: 'doctors/set',
      component: 'health',
      id: doctor.id,
      description_private: descString
    };

    const response =await postToServer(API_MODX_URL, { ...requestData }) as TResponsePrivateDesc;
    // changedData

    if (response?.data?.ok && response?.data?.message) { alert(response.data.message); } else { alert('Не работает сохранение описания доктора, обратитесь к разработчикам'); }
    return response;
  }
}
